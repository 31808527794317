/* eslint-disable no-unused-vars */
import axios from '@axios'
import jwt from 'jsonwebtoken'
// import Cookies from 'js-cookie'

export default {
  namespaced: true,
  state: {
    billingCustomer: null,
    subscriptionLineItems: [],
    products: [],
  },
  getters: {
    products: state => state.products,
    billingCustomer: state => state.billingCustomer,
    subscriptionLineItems: state => state.subscriptionLineItems,
  },
  mutations: {
    SET_PRODUCTS(state, products) {
      state.products = products
    },
    SET_BILLING_CUSTOMER(state, billingCustomer) {
      state.billingCustomer = billingCustomer
    },
    SET_SUBSCRIPTION_LINE_ITEMS(state, subscriptionLineItems) {
      state.subscriptionLineItems = subscriptionLineItems
    },
  },
  actions: {
    async getProducts({ commit }) {
      const response = await axios.get('/api/products')
      commit('billing/SET_PRODUCTS', response.data, { root: true })
      return response
    },
    async createSubscription({ dispatch }, payload) {
      const response = await axios.post('/api/subscriptions', payload)
      try {
        // const activeSubscription = response.data.stripe_subscription_object.status === 'active' ? true : false;
        await dispatch('billing/getBillingCustomer', response.data.billing_customer_id, { root: true })
        await dispatch('auth/getUserProfile', true, { root: true })
      } catch (e) {
        console.error(e.message)
      }
      return response
    },
    async createCheckoutSession({ dispatch }, payload) {
      const response = await axios.post('/api/payments/create-checkout-session', payload)
      return response
    },
    async updateSubscription({ dispatch, commit }, payload) {
      const { id, ...reduced } = payload
      const response = await axios.put(`/api/subscriptions/${id}`, reduced)
      try {
        // const activeSubscription = response.data.subscription.stripe_subscription_object.status === 'active' ? true : false;
        // commit(`SET_ACTIVE_SUBSCRIPTION`, activeSubscription);
        await dispatch('billing/getBillingCustomer', response.data.subscription.billing_customer_id, { root: true })
        // set token
        // localStorage.setItem("token", response.headers.token);
        const decoded = jwt.decode(response.headers.token)
        // Update user details
        commit('auth/UPDATE_USER_INFO', { decoded }, { root: true })
      } catch (e) {
        console.error(e.message)
      }
      return response
    },
    async getBillingCustomer({ commit }, id) {
      const response = await axios.get(`/api/billing-customers/${id}`)
      commit('billing/SET_BILLING_CUSTOMER', response.data, { root: true })
      return response
    },
    async calculateSubscriptionPrice({ commit }, payload) {
      const response = await axios.post('/api/subscriptions/calculate-price', payload)
      commit('billing/SET_SUBSCRIPTION_LINE_ITEMS', response.data, { root: true })
      return response
    },
    async createStripePortalSession({ commit }, payload) {
      try {
        const response = await axios.post('/api/payments/billing-portal-session', payload)
        return response
      } catch (e) {
        console.error(e.message)
        throw e
      }
    },
  },
}
