import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth'
import user from './user'
import contactRequests from './contact-requests'
import billing from './billing'
import role from './role'
import office from './office'
import loggableItems from './loggable-items'
import resource from './resource'
import statistics from './statistics'
import report from './report'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    auth,
    billing,
    contactRequests,
    office,
    verticalMenu,
    role,
    user,
    loggableItems,
    resource,
    'app-ecommerce': ecommerceStoreModule,
    statistics,
    report,
  },
  strict: process.env.DEV,
})
