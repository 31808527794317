/* eslint-disable no-unused-vars */
import axios from '@axios'
// import Cookies from 'js-cookie'

export default {
  namespaced: true,
  state: {
    currentDashboardAnalytics: null,
  },
  getters: {
    currentDashboardAnalytics: state => state.currentDashboardAnalytics,
  },
  mutations: {
    SET_CURRENT_DASHBOARD_ANALYTICS(state, analytics) {
      state.currentDashboardAnalytics = analytics
    },
  },
  actions: {
    async getDashboardAnalytics({ commit }, payload) {
      let response
      if (payload) {
        response = await axios.get(`/api/statistics/dashboard?user_id=${payload.id}&role_id=${payload.currentRole.id}`)
      } else {
        response = await axios.get('/api/statistics/dashboard')
      }
      commit('statistics/SET_CURRENT_DASHBOARD_ANALYTICS', response.data.analytics, { root: true })
      return response
    },
  },
}
