/* eslint-disable no-unused-vars */
import axios from '@axios'
// import Cookies from 'js-cookie'

export default {
  namespaced: true,
  state: {
    roles: [],
  },
  getters: {
    roles: state => state.roles,
  },
  mutations: {
    UPDATE_ROLES(state, roles) {
      state.roles = roles
    },
  },
  actions: {
    async getRoles({ commit }) {
      const roles = await axios.get('/api/roles')
      commit('role/UPDATE_ROLES', roles.data, { root: true })
      return roles
    },
  },
}
