/* eslint-disable no-unused-vars */
import axios from '@axios'
// import Cookies from 'js-cookie'

export default {
  namespaced: true,
  state: {
    loggableItemHistories: [],
    dailyLogQuestions: [],
    goldStandardTimes: [],
    selectedLoggableItemHistory: null,
    loggableItems: [],
    loggableItem: null,
    loggableItemPreview: null,
    questions: [],
    inputTypes: [],
    optionGroups: [],
    questionOrders: [],
    surveyUrl: null,
    embedToken: null,
  },
  getters: {
    loggableItemHistories: state => state.loggableItemHistories,
    dailyLogQuestions: state => state.dailyLogQuestions,
    goldStandardTimes: state => state.getGoldStandardTimes,
    selectedLoggableItemHistory: state => state.selectedLoggableItemHistory,
    loggableItems: state => state.loggableItems,
    loggableItem: state => state.loggableItem,
    loggableItemPreview: state => state.loggableItemPreview,
    questions: state => state.questions,
    inputTypes: state => state.inputTypes,
    optionGroups: state => state.optionGroups,
    questionOrders: state => state.questionOrders,
    surveyUrl: state => state.surveyUrl,
    embedToken: state => state.embedToken,
  },
  mutations: {
    SET_LOGGABLE_ITEM_HISTORIES(state, histories) {
      state.loggableItemHistories = histories
    },
    SET_DAILY_LOG_QUESTIONS(state, questions) {
      state.dailyLogQuestions = questions
    },
    SET_GOLD_STANDARD_TIMES(state, goldStandardTimes) {
      state.goldStandardTimes = goldStandardTimes
    },
    SET_SELECTED_LOGGABLE_ITEM_HISTORY(state, loggableItemHistory) {
      state.selectedLoggableItemHistory = loggableItemHistory
    },
    SET_LOGGABLE_ITEMS(state, items) {
      state.loggableItems = items
    },
    SET_LOGGABLE_ITEM(state, loggableItem) {
      state.loggableItem = loggableItem
    },
    SET_LOGGABLE_ITEM_PREVIEW(state, item) {
      state.loggableItemPreview = item
    },
    SET_QUESTIONS(state, questions) {
      state.questions = questions
    },
    SET_INPUT_TYPES(state, inputTypes) {
      state.inputTypes = inputTypes
    },
    SET_OPTION_GROUPS(state, optionGroups) {
      state.optionGroups = optionGroups
    },
    SET_QUESTION_ORDERS(state, questionOrders) {
      state.questionOrders = questionOrders
    },
    SET_SURVEY_URL(state, url) {
      state.surveyUrl = url
    },
    SET_EMBED_TOKEN(state, token) {
      state.embedToken = token
    },
  },
  actions: {
    async getLoggableItemHistories({ commit }, user) {
      let loggableItemHistories
      if (user) {
        loggableItemHistories = await axios.get(`/api/loggable_item_histories?user_id=${user.id}&role_id=${user.currentRole.id}`)
        commit('loggableItems/SET_LOGGABLE_ITEM_HISTORIES', loggableItemHistories.data, { root: true })
      } else {
        loggableItemHistories = await axios.get('/api/loggable_item_histories')
        commit('loggableItems/SET_LOGGABLE_ITEM_HISTORIES', loggableItemHistories.data, { root: true })
      }
      return loggableItemHistories
    },
    async getDailyLogQuestions({ commit }, roleId) {
      const dailyLogQuestions = await axios.get(`/api/loggable_items?role_id=${roleId}&loggable_item_type_id=1`)
      commit('loggableItems/SET_LOGGABLE_ITEM', dailyLogQuestions.data, { root: true })
      const sorted = dailyLogQuestions.data.question_orders.sort((a, b) => a.question_number - b.question_number)
      commit('loggableItems/SET_DAILY_LOG_QUESTIONS', sorted, { root: true })
      return dailyLogQuestions
    },
    async setSurveyUrlAndToken({ commit }, payload) {
      commit('loggableItems/SET_SURVEY_URL', payload.survey_url, { root: true })
      commit('loggableItems/SET_EMBED_TOKEN', payload.embed_token, { root: true })
    },
    async getGoldStandardTimes({ commit }) {
      const response = await axios.get('/api/gold_standard_times')
      commit('loggableItems/SET_GOLD_STANDARD_TIMES', response.data, { root: true })
      return response
    },
    async createLoggableItemHistory({ dispatch }, payload) {
      const response = await axios.post('/api/loggable_item_histories', payload)
      try {
        await dispatch('loggableItems/getLoggableItemHistories', '', { root: true })
      } catch (e) {
        console.debug(`Error dispatching in createLoggableItemHistory(): ${e.message}`)
      }
      return response
    },
    async createLoggableItemHistoryDraft({ dispatch }, payload) {
      const response = await axios.post('/api/loggable_item_histories_drafts', payload)
      await dispatch('loggableItems/getLoggableItemHistories', '', { root: true })
      return response
    },
    async createResponse({ dispatch }, payload) {
      const response = await axios.post('/api/responses', payload)
      // dispatch('getLoggableItemHistories');
      return response
    },
    async deleteLoggableItemHistory({ dispatch }, id) {
      const response = await axios.delete(`/api/loggable_item_histories/${id}`)
      await dispatch('loggableItems/getLoggableItemHistories', '', { root: true })
      return response
    },
    async getLoggableItemHistory({ commit }, id) {
      const log = await axios.get(`/api/loggable_item_histories/${id}`)
      commit('loggableItems/SET_SELECTED_LOGGABLE_ITEM_HISTORY', log.data, { root: true })
      return log
    },
    async updateLoggableItemHistory({ commit }, payload) {
      const response = await axios.put(`/api/loggable_item_histories/${payload.id}`, payload)
      commit('loggableItems/SET_SELECTED_LOGGABLE_ITEM_HISTORY', response.data, { root: true })
      return response
    },
    async deleteLoggableItemHistoryResponses({ commit }, id) {
      const response = await axios.delete(`/api/loggable_item_histories/${id}/responses`)
      return response
    },
    async updateLoggableItemHistoryDraft({ dispatch }, payload) {
      const response = await axios.put(`/api/loggable_item_histories_drafts/${payload.id}`, payload)
      await dispatch('loggableItems/getLoggableItemHistories', '', { root: true })
      return response
    },
    async getLoggableItems({ commit }) {
      const response = await axios.get('/api/loggable_items')
      commit('loggableItems/SET_LOGGABLE_ITEMS', response.data, { root: true })
      return response
    },
    async getLoggableItem({ commit }, id) {
      const log = await axios.get(`/api/loggable_items/${id}`)
      const sorted = log.data.question_orders.sort((a, b) => a.question_number - b.question_number)
      log.data.question_orders = sorted
      commit('loggableItems/SET_LOGGABLE_ITEM', log.data, { root: true })
      commit('loggableItems/SET_LOGGABLE_ITEM_PREVIEW', log.data, { root: true })
      return log
    },
    async setLoggableItemPreview({ commit }, item) {
      commit('loggableItems/SET_LOGGABLE_ITEM_PREVIEW', item, { root: true })
    },
    async getQuestions({ commit }) {
      const response = await axios.get('/api/questions')
      commit('loggableItems/SET_QUESTIONS', response.data, { root: true })
      return response
    },
    async createQuestionOrder({ dispatch }, payload) {
      const response = await axios.post('/api/question_orders', payload)
      await dispatch('loggableItems/getLoggableItems', '', { root: true })
      await dispatch('loggableItems/getLoggableItem', payload.loggable_item_id, { root: true })
      return response
    },
    async getInputTypes({ commit }) {
      const response = await axios.get('/api/input_types')
      commit('loggableItems/SET_INPUT_TYPES', response.data, { root: true })
      return response
    },
    async getOptionGroups({ commit }) {
      const response = await axios.get('/api/option_groups')
      commit('loggableItems/SET_OPTION_GROUPS', response.data, { root: true })
      return response
    },
    async createQuestion({ dispatch }, payload) {
      const response = await axios.post('/api/questions', payload)
      await dispatch('loggableItems/getQuestions', '', { root: true })
      return response
    },
    async updateQuestion({ dispatch }, payload) {
      const response = await axios.put(`/api/questions/${payload.id}`, payload)
      await dispatch('loggableItems/getQuestions', '', { root: true })
      // commit('SET_QUESTIONS', response.data);
      return response
    },
    async updateQuestionOrder({ dispatch }, payload) {
      const response = await axios.put(`/api/question_orders/${payload.id}`, payload)
      await dispatch('loggableItems/getQuestionOrders', '', { root: true })
      // commit('SET_QUESTIONS', response.data);
      return response
    },
    async getQuestionOrders({ commit }) {
      const response = await axios.get('/api/question_orders')
      commit('loggableItems/SET_QUESTION_ORDERS', response.data, { root: true })
      return response
    },
    async bulkUpdateQuestionOrders({ dispatch }, payload) {
      const response = await axios.patch('/api/question_orders', payload)
      await dispatch('loggableItems/getQuestionOrders', '', { root: true })
      return response
    },
  },
}
