// import ability from './ability'
import { Ability } from '@casl/ability'
import store from '@/store'
import { initialAbility } from './config'

export const canNavigate = to => {
  // this pulls user ability from the active user object in the vuex store
  const activeUser = store.getters['auth/activeUser']
  const existingAbility = activeUser ? activeUser.ability[activeUser.currentRole.name] : null
  const ability = new Ability(existingAbility || initialAbility)
  return to.matched.some(route => ability.can(route.meta.action || 'read', route.meta.resource))
}

export const _ = undefined
