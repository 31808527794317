export default [
  {
    path: '/log-entries',
    name: 'log-entries',
    component: () => import('@/views/pages/log-entries/LogEntries.vue'),
    meta: {
      // redirectIfLoggedIn: true,
      pageTitle: 'Log Entries',
      breadcrumb: [
        {
          text: 'Log Entries',
          active: true,
        },
      ],
      resource: 'LoggableItem',
      action: 'read',
    },
  },
  {
    path: '/log-entries/new',
    name: 'log-entries-new',
    component: () => import('@/views/pages/log-entries/LogEntriesNew.vue'),
    meta: {
      pageTitle: 'New Log Entry',
      breadcrumb: [
        {
          text: 'Log Entries',
          to: { name: 'log-entries' },
          active: false,
        },
        {
          text: 'New Log Entry',
          active: true,
        },
      ],
      resource: 'LoggableItem',
      action: 'create',
    },
  },
  {
    path: '/log-entries/:loggableItemHistoryId',
    name: 'log-entries-view',
    component: () => import('@/views/pages/log-entries/LogEntriesView.vue'),
    meta: {
      pageTitle: 'View Log Entry',
      breadcrumb: [
        {
          text: 'Log Entries',
          to: { name: 'log-entries' },
          active: false,
        },
        {
          text: 'View Log Entry',
          active: true,
        },
      ],
      resource: 'LoggableItem',
      action: 'read',
    },
  },
  {
    path: '/log-entries/:loggableItemHistoryId/edit',
    name: 'log-entries-edit',
    component: () => import('@/views/pages/log-entries/LogEntriesEdit.vue'),
    meta: {
      pageTitle: 'Edit Log Entry',
      breadcrumb: [
        {
          text: 'Log Entries',
          to: { name: 'log-entries' },
          active: false,
        },
        {
          text: 'Edit Log Entry',
          active: true,
        },
      ],
      resource: 'LoggableItem',
      action: 'read',
    },
  },
]
