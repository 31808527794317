export default [
  {
    path: '/learning-resources',
    name: 'learning-resources',
    component: () => import('@/views/pages/learning-resources/LearningResources.vue'),
    meta: {
      resource: 'Resource',
      action: 'read',
      pageTitle: 'Learning Resources',
      breadcrumb: [
        {
          text: 'Learning Resources',
          active: true,
        },
      ],
    },
  },
]
