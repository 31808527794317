export default [
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/views/pages/reports/Reports.vue'),
    meta: {
      // redirectIfLoggedIn: true,
      pageTitle: 'Reports',
      breadcrumb: [
        {
          text: 'Reports',
          active: true,
        },
      ],
      resource: 'Report',
      action: 'read',
    },
  },
  {
    path: '/reports/:reportUuid',
    name: 'reports-view',
    component: () => import('@/views/pages/reports/ReportsView.vue'),
    meta: {
      pageTitle: 'View Report',
      breadcrumb: [
        {
          text: 'Reports',
          to: { name: 'reports' },
          active: false,
        },
        {
          text: 'View Report',
          active: true,
        },
      ],
      resource: 'Report',
      action: 'read',
    },
  },
]
