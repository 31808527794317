/* eslint-disable no-unused-vars */
import axios from '@axios'
import Cookies from 'js-cookie'

export default {
  namespaced: true,
  state: {
    activeUser: null,
    redirectPath: null,
    userState: null,
  },
  getters: {
    activeUser: state => state.activeUser,
    redirectPath: state => state.redirectPath,
    userState: state => state.userState,
  },
  mutations: {
    UPDATE_USER_INFO(state, payload) {
      state.activeUser = payload
      if (payload) {
        const { default_role, roles } = payload
        const currentRole = Cookies.get('currentRole')
        if (currentRole) {
          // eslint-disable-next-line radix
          const role = roles.find(r => r.id === parseInt(currentRole))
          if (!role) {
            // eslint-disable-next-line camelcase
            state.activeUser.currentRole = default_role
          } else {
            state.activeUser.currentRole = role
          }
        } else {
          // eslint-disable-next-line camelcase
          state.activeUser.currentRole = default_role
        }
      }
    },
    SET_REDIRECT_PATH(state, redirectPath) {
      state.redirectPath = redirectPath
    },
    SET_USER_STATE(state, userState) {
      state.userState = userState
    },
    SWITCH_ROLE(state, desiredRoleId) {
      const { roles } = state.activeUser
      const desiredRole = roles.find(r => r.id === desiredRoleId)
      if (desiredRole) {
        state.activeUser.currentRole = desiredRole
        Cookies.set('currentRole', desiredRole.id)
      }
    },
  },
  actions: {
    updateUserInfo({ commit }, payload) {
      commit('auth/UPDATE_USER_INFO', payload, { root: true })
    },
    setRedirectPath({ commit }, redirectPath) {
      commit('auth/SET_REDIRECT_PATH', redirectPath, { root: true })
    },
    async loginUser({ commit }, payload) {
      const response = await axios.post('/api/users/login', payload)
      commit('auth/UPDATE_USER_INFO', response.data, { root: true })
      return response
    },
    async logoutUser({ dispatch }, payload) {
      Cookies.remove('logged_in')
      Cookies.remove('currentRole')
      const response = await axios.post('/api/users/logout')
      await dispatch('auth/updateUserInfo', null, { root: true })
      return response
    },
    async recoverPassword({ commit }, payload) {
      const response = await axios.post('/api/users/password-reset-token', payload)
      return response
    },
    // eslint-disable-next-line no-empty-pattern
    async getUserProfile({ dispatch }, refresh = false) {
      let response
      if (refresh) {
        response = await axios.get('/api/users/me?refresh=true')
      } else {
        response = await axios.get('/api/users/me')
      }
      await dispatch('auth/updateUserInfo', response.data, { root: true })
      // await commit('SET_USER_STATE', response.data.state)
      return response
    },
    async verifySignupToken({ commit }, payload) {
      const response = await axios.post('/api/contact-requests/verify-token', payload)
      return response
    },
    async registerUser({ commit, dispatch }, payload) {
      const response = await axios.post('/api/users/register', payload)
      // commit('auth/SET_USER_STATE', response.data.state, { root: true })
      // Update user details
      // await dispatch('auth/getUserProfile', true, { root: true })
      return response
    },
    async resendVerificationCode() {
      const response = await axios.post('/api/users/resend-verification-code')
      return response
    },
    async verifyAccount({ dispatch }, payload) {
      const response = await axios.post('/api/users/verify', payload)
      await dispatch('auth/getUserProfile', '', { root: true })
      return response
    },
    async changeTemporaryPassword({ dispatch }, payload) {
      const response = await axios.post('/api/users/change-temporary-password', payload)
      await dispatch('auth/getUserProfile', true, { root: true })
      return response
    },
    async validatePasswordReset({ commit }, payload) {
      const response = await axios.put('/api/users/password-reset-token', payload)
      return response
    },
    async changePassword({ commit }, payload) {
      const response = await axios.post('/api/users/change-password', payload)
      return response
    },
    async switchRole({ commit }, desiredRoleId) {
      commit('auth/SWITCH_ROLE', desiredRoleId, { root: true })
    },
  },
}
