/* eslint-disable no-unused-vars */
import axios from '@axios'
// import Cookies from 'js-cookie'

export default {
  namespaced: true,
  state: {
    contactRequests: [],
  },
  getters: {
    contactRequests: state => state.contactRequests,
  },
  mutations: {
    SET_CONTACT_REQUESTS(state, contactRequests) {
      state.contactRequests = contactRequests
    },
  },
  actions: {
    async getContactRequests({ commit }) {
      const response = await axios.get('/api/contact-requests')
      commit('contactRequests/SET_CONTACT_REQUESTS', response.data, { root: true })
      return response
    },
    async updateContactRequest({ dispatch }, req) {
      const response = await axios.put(`/api/contact-requests/${req.id}`, req.payload)
      await dispatch('contactRequests/getContactRequests', '', { root: true })
      return response
    },
    async deleteContactRequest({ dispatch }, id) {
      const response = await axios.delete(`/api/contact-requests/${id}`)
      await dispatch('contactRequests/getContactRequests', '', { root: true })
      return response
    },
  },
}
