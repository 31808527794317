/* eslint-disable no-unused-vars */
import axios from '@axios'
// import Cookies from 'js-cookie'

export default {
  namespaced: true,
  state: {
    users: [],
    selectedUser: null,
  },
  getters: {
    users: state => state.users,
    selectedUser: state => state.selectedUser,
  },
  mutations: {
    UPDATE_USERS(state, users) {
      state.users = users
    },
    SET_SELECTED_USER(state, user) {
      state.selectedUser = user
    },
  },
  actions: {
    async updateUser({ dispatch }, payload) {
      const { id, ...redacted } = payload
      const response = await axios.patch(`/api/users/${id}`, redacted)
      await dispatch('auth/getUserProfile', '', { root: true })
      await dispatch('user/getUsers', '', { root: true })
      return response
    },
    async createUser({ dispatch }, payload) {
      const response = await axios.post('/api/users', payload)
      await dispatch('user/getUsers', '', { root: true })
      return response
    },
    async getUsers({ commit }) {
      const users = await axios.get('/api/users')
      commit('user/UPDATE_USERS', users.data, { root: true })
      return users
    },
    async acceptTerms({ commit }, payload) {
      const response = await axios.post('/api/users/accept-terms')
      return response
    },
    async getUser({ commit }, id) {
      const response = await axios.get(`/api/users/${id}`)
      commit('user/SET_SELECTED_USER', response.data, { root: true })
      return response
    },
    async deactivateUser({ dispatch }, id) {
      const response = await axios.post(`/api/users/${id}/deactivate`)
      await dispatch('user/getUsers', '', { root: true })
      return response
    },
    async reactivateUser({ dispatch }, id) {
      const response = await axios.post(`/api/users/${id}/reactivate`)
      await dispatch('user/getUsers', '', { root: true })
      return response
    },
  },
}
