export default [
  {
    path: '/offices',
    name: 'offices',
    component: () => import('@/views/pages/offices/Offices.vue'),
    meta: {
      resource: 'Office',
      action: 'read',
      pageTitle: 'Offices',
      breadcrumb: [
        {
          text: 'Offices',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/offices/:officeId',
  //   name: 'offices-view',
  //   component: () => import('@/views/pages/offices/OfficesView.vue'),
  //   meta: {
  //     pageTitle: 'View Office',
  //     breadcrumb: [
  //       {
  //         text: 'Offices',
  //         to: { name: 'offices' },
  //         active: false,
  //       },
  //       {
  //         text: 'View Office',
  //         active: true,
  //       },
  //     ],
  //     resource: 'Office',
  //     action: 'read',
  //   },
  // },
  // {
  //   path: '/offices/:officeId/edit',
  //   name: 'offices-edit',
  //   component: () => import('@/views/pages/offices/OfficesEdit.vue'),
  //   meta: {
  //     pageTitle: 'Edit Office',
  //     breadcrumb: [
  //       {
  //         text: 'Offices',
  //         to: { name: 'offices' },
  //         active: false,
  //       },
  //       {
  //         text: 'Edit Office',
  //         active: true,
  //       },
  //     ],
  //     resource: 'Office',
  //     action: 'update',
  //   },
  // },
]
