export default [
  {
    path: '/onboarding',
    name: 'onboarding',
    component: () => import('@/views/pages/onboarding/Onboarding.vue'),
    meta: {
      redirectIfLoggedIn: false,
      resource: 'Onboarding',
      action: 'read',
    },
    // children: [
    //   {
    //     path: 'office-setup',
    //     name: 'onboarding-office-setup',
    //     component: () => import('@/views/pages/onboarding/steps/OfficeSetup.vue'),
    //   },
    //   {
    //     path: 'subscribe',
    //     name: 'onboarding-subscribe',
    //     component: () => import('@/views/pages/onboarding/steps/SubscriptionForm.vue'),
    //   },
    //   {
    //     path: 'account-setup',
    //     name: 'onboarding-account-setup',
    //     component: () => import('@/views/pages/onboarding/steps/AccountSetup.vue'),
    //   },
    //   {
    //     path: 'terms',
    //     name: 'onboarding-accept-terms',
    //     component: () => import('@/views/pages/onboarding/steps/AcceptTerms.vue'),
    //   },
    //   {
    //     path: 'change-password',
    //     name: 'onboarding-change-password',
    //     component: () => import('@/views/pages/onboarding/steps/ChangePassword.vue'),
    //   },
    // ],
  },
]
