/* eslint-disable camelcase */
/* eslint-disable no-lonely-if */
import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import {
  isUserLoggedIn, setRedirectPath, getUserData, getHomeRouteForLoggedInUser,
} from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import onboarding from './routes/onboarding'
import logEntries from './routes/log-entries'
import learningResources from './routes/learning-resources'
import users from './routes/users'
import offices from './routes/offices'
import questionnaires from './routes/questionnaires'
import reports from './routes/reports'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard' } },
    ...apps,
    ...dashboard,
    ...onboarding,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    ...logEntries,
    ...learningResources,
    ...users,
    ...offices,
    ...questionnaires,
    ...reports,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  try {
    const isLoggedIn = await isUserLoggedIn()
    console.debug(`Is logged in: ${isLoggedIn}`)

    // do an ACL check here to see if the path can be navigated to
    if (!canNavigate(to)) {
      console.debug('ACL error. Cannot navigate')
      if (!isLoggedIn) return next({ name: 'auth-login', query: { redirect: to.fullPath } })
      return next({ name: 'misc-not-authorized' })
    }

    const userData = getUserData()

    // Redirect if already logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
      return next(getHomeRouteForLoggedInUser(userData ? userData.currentRole.name : null), true)
    }

    // custom routing logic based on user state
    if (!to.meta.skipRoutingLogic && isLoggedIn) {
      try {
        if (from.query && from.query.redirect) {
          const redirectPath = from.query.redirect
          // eslint-disable-next-line no-param-reassign
          from.query.redirect = null
          return next({ path: redirectPath })
        }
      } catch (e) {
        console.debug('Could not redirect from query params')
      }
      const {
        state, verified, currentRole, office,
      } = userData
      // since isUserLoggedIn didn't barf with a 401, we can reset the redirectPath state variable
      setRedirectPath()
      console.debug(`State: ${state}, Office: ${office}, Verified: ${verified}, Role: ${currentRole.name}`)
      if (currentRole.name === 'admin') {
        if (to.matched.some(record => record.path === '/onboarding')) {
          return next(getHomeRouteForLoggedInUser(userData ? userData.currentRole.name : null), true)
        }
      } else if (currentRole.name === 'patient') {
        if (to.matched.some(record => record.path !== '/log-entries/new')) {
          return next(getHomeRouteForLoggedInUser(userData ? userData.currentRole.name : null), true)
        }
      } else {
        if (state !== 'activated') {
          if (state === 'verified' && currentRole.name !== 'dentist' && currentRole.name !== 'office_manager') {
            return next({ name: 'misc-not-authorized' })
          }
          if (to.matched.some(record => record.path !== '/onboarding')) {
            return next({
              name: 'onboarding',
              replace: true,
            })
          }
        // eslint-disable-next-line no-else-return
        } else {
          const { terms_accepted_at, has_temporary_password } = userData
          const { onboarding_completed_at, active_subscription } = office

          // if you're an office manager or a dentist and you haven't finished onboarding, go onboard
          if (currentRole.name === 'dentist' || currentRole.name === 'office_manager') {
            if (!onboarding_completed_at || !active_subscription) {
              if (to.matched.some(record => record.path !== '/onboarding')) {
                return next({
                  name: 'onboarding',
                  replace: true,
                })
              }
            } else {
              // user is onboarded. if they attempt to access onboarding, redirect em
              if (to.matched.some(record => record.path === '/onboarding')) {
                return next(getHomeRouteForLoggedInUser(userData ? userData.currentRole.name : null), true)
              }
            }
          } else {
            // ordinary users haven't onboarded if they haven't accepted terms or reset their temp password
            if (!terms_accepted_at || has_temporary_password) {
              if (to.matched.some(record => record.path !== '/onboarding')) {
                return next({
                  name: 'onboarding',
                  replace: true,
                })
              }
            } else {
              // user is onboarded. if they attempt to access onboarding, redirect em
              if (to.matched.some(record => record.path === '/onboarding')) {
                return next(getHomeRouteForLoggedInUser(userData ? userData.currentRole.name : null), true)
              }
            }
          }

          // if you have finished onboarding, redirect if you access the onboarding link
          // if (role.name !== 'dentist' && role.name !== 'office_manager') {
          //   if (!terms_accepted_at || has_temporary_password) {
          //     if (to.matched.some(record => record.path !== '/onboarding')) {
          //       return next({
          //         name: 'onboarding',
          //         replace: true,
          //       })
          //     }
          //   } else {
          //     console.debug('booboo')
          //     if (to.matched.some(record => record.path === '/onboarding')) {
          //       console.log('boo1')
          //       return next(getHomeRouteForLoggedInUser(userData ? userData.role.name : null), true)
          //     }
          //   }
          // } else {
          //   if (!onboarding_completed_at || !active_subscription) {
          //     if (to.matched.some(record => record.path !== '/onboarding')) {
          //       return next({
          //         name: 'onboarding',
          //         replace: true,
          //       })
          //     }
          //   } else {
          //     if (to.matched.some(record => record.path === '/onboarding')) {
          //       console.log('boo2')
          //       return next(getHomeRouteForLoggedInUser(userData ? userData.role.name : null), true)
          //     }
          //   }
          // }

          // if (to.matched.some(record => record.path === '/onboarding')) {
          //   return next(getHomeRouteForLoggedInUser(userData ? userData.role.name : null), true)
          // }
          // if you attempt to access /onboarding when already activated, redirect

          // return next({ path: '/help' })
          // if ((role.name === 'dentist' || role.name === 'office_manager') && !office.onboarding_completed_at) {
          //   return next({
          //     name: 'onboarding',
          //     replace: true,
          //   })
          // }
        }
        // return next(getHomeRouteForLoggedInUser(userData ? userData.role.name : null, true))
        /*
        if (!terms_accepted_at && to.name !== 'onboarding-terms') {
          return next({
            name: 'onboarding-terms',
            replace: true,
          })
        }
        if (has_temporary_password && terms_accepted_at && to.name !== 'onboarding-change-password') {
          return next({
            name: 'onboarding-change-password',
            replace: true,
          })
        }
        if ((state === 'unverified') && verified === false && (role.name === 'dentist' || role.name === 'office_manager') && to.name !== 'page-verify-account') {
        // verify account
          return next({
            name: 'page-verify-account',
            replace: true,
          })
        }
        if ((role.name === 'dentist' || role.name === 'office_manager') && state === 'verified' && verified === true) {
        // finish onboarding
          if (office_id == null && to.name !== 'onboarding-office-setup') {
            return next({
              name: 'onboarding-office-setup',
              replace: true,
            })
          }
          if (office && office.active_subscription === false && to.name !== 'onboarding-subscribe') {
            console.log('please subscribe')
            return next({
              name: 'onboarding',
              replace: true,
            })
          }
        } else if (state === 'activated') {
        // this user is activated already and should not be seeing onboarding pages
        // if (to.matched.some(record => record.path === '/onboarding') && state === 'activated' && terms_accepted_at && !has_temporary_password) {
        //   return next(getHomeRouteForLoggedInUser(userData ? userData.role.name : null, true))
        // }
          if (terms_accepted_at && !has_temporary_password) {
            return next(getHomeRouteForLoggedInUser(userData ? userData.role.name : null, true))
          }
        }
        // we have an invalid state
        return next({ name: 'misc-not-authorized' })
        */
      }
      return next()
    }
    return next()
  } catch (e) {
    console.debug(e.message)
    return next({ name: 'misc-error' })
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.onError(err => {
  console.debug(`Router Error: ${err.message}`)
})

export default router
