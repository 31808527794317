/* eslint-disable no-unused-vars */
import axios from '@axios'
// import Cookies from 'js-cookie'

export default {
  namespaced: true,
  state: {
    offices: [],
    selectedOffice: null,
  },
  getters: {
    offices: state => state.offices,
    selectedOffice: state => state.selectedOffice,
  },
  mutations: {
    SET_OFFICES(state, offices) {
      state.offices = offices
    },
    SET_SELECTED_OFFICE(state, office) {
      state.selectedOffice = office
    },
  },
  actions: {
    async completeOnboarding({ dispatch }, payload) {
      const { id, ...redacted } = payload
      const response = await axios.patch(`/api/offices/${id}`, redacted)
      await dispatch('auth/getUserProfile', '', { root: true })
      return response
    },
    async createOffice({ dispatch }, payload) {
      const office = await axios.post('/api/offices', payload)
      await dispatch('auth/getUserProfile', '', { root: true })
      await dispatch('office/getOffices', '', { root: true })
      return office
    },
    async getOffices({ commit }) {
      const offices = await axios.get('/api/offices')
      commit('office/SET_OFFICES', offices.data, { root: true })
      return offices
    },
    async getOffice({ commit }, id) {
      const office = await axios.get(`/api/offices/${id}`)
      commit('office/SET_SELECTED_OFFICE', office.data, { root: true })
      return office
    },
    async updateOffice({ commit, dispatch }, payload) {
      const office = await axios.patch(`/api/offices/${payload.id}`, payload)
      commit('office/SET_SELECTED_OFFICE', office.data, { root: true })
      await dispatch('office/getOffices', '', { root: true })
      return office
    },
    async accessPatientPortal({ dispatch }, payload) {
      const response = await axios.post('/api/offices/patient-portal', payload)
      await dispatch('auth/getUserProfile', true, { root: true })
      return response
    },
  },
}
