// import useJwt from '@/auth/jwt/useJwt'
import store from '@/store'

/**
 * Return if user is logged in
 * DenTyme will make a call to the backend here to check if the user is logged in.
 * It will make a request with the token if present in the HTTP-only cookie
 * Success means user is logged in and a 401 error means user is not logged in.
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = async () => {
  let isLoggedIn = false
  try {
    if (!store.state.auth.activeUser) {
      await store.dispatch('auth/getUserProfile')
      isLoggedIn = true
    } else {
      isLoggedIn = true
    }
  } catch (e) {
    console.debug(`isUserLoggedIn error: ${e.message}`)
  } finally {
    // eslint-disable-next-line no-unsafe-finally
    return isLoggedIn
  }
  // return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

/**
 * Sets a redirect path in vuex
 */
// eslint-disable-next-line arrow-body-style
export const setRedirectPath = (redirectPath = null) => {
  store.dispatch('auth/setRedirectPath', redirectPath, { root: true })
}

export const getUserData = () => store.getters['auth/activeUser']

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
// eslint-disable-next-line arrow-body-style
export const getHomeRouteForLoggedInUser = (userRole, replace = false) => {
  // if (userRole === 'admin' || userRole === 'office_manager') return '/'
  // if (userRole === 'client') return { name: 'access-control' }
  // return { name: 'auth-login', replace }
  if (userRole === 'patient') {
    return { name: 'log-entries-new', replace }
  }
  return { path: '/', replace }
}
