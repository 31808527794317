export default [
  {
    path: '/questionnaires',
    name: 'questionnaires',
    component: () => import('@/views/pages/questionnaires/Questionnaires.vue'),
    meta: {
      resource: 'LoggableItem',
      action: 'edit',
      pageTitle: 'Questionnaires',
      breadcrumb: [
        {
          text: 'Questionnaires',
          active: true,
        },
      ],
    },
  },
  {
    path: '/questionnaires/:loggableItemId/edit',
    name: 'questionnaires-edit',
    component: () => import('@/views/pages/questionnaires/QuestionnairesEdit.vue'),
    meta: {
      resource: 'LoggableItem',
      action: 'edit',
      pageTitle: 'Edit Questionnaire',
      breadcrumb: [
        {
          text: 'Questionnaires',
          to: { name: 'questionnaires' },
          active: false,
        },
        {
          text: 'Edit Questionnaire',
          active: true,
        },
      ],
    },
  },
]
