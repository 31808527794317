/* eslint-disable no-unused-vars */
import axios from '@axios'
// import Cookies from 'js-cookie'

export default {
  namespaced: true,
  state: {
    resourceFiles: [],
    tags: [],
    selectedResource: null,
  },
  getters: {
    resourceFiles: state => state.resourceFiles,
    tags: state => state.tags,
    selectedResource: state => state.selectedResource,
  },
  mutations: {
    SET_RESOURCE_FILES(state, resourceFiles) {
      state.resourceFiles = resourceFiles
    },
    SET_TAGS(state, tags) {
      state.tags = tags
    },
    SET_SELECTED_RESOURCE(state, resource) {
      state.selectedResource = resource
    },
  },
  actions: {
    async getResourceFiles({ commit }) {
      const response = await axios.get('/api/resource-files')
      commit('resource/SET_RESOURCE_FILES', response.data, { root: true })
      return response
    },
    async getResourceFile({ commit }, uuid) {
      const response = await axios.get(`/api/resource-files/${uuid}`)
      commit('resource/SET_SELECTED_RESOURCE', response.data, { root: true })
      return response
    },
    async updateResourceFile({ dispatch }, payload) {
      const response = await axios.patch(`/api/resource-files/${payload.uuid}`, payload)
      await dispatch('resource/getResourceFiles', '', { root: true })
      return response
    },
    async deleteResourceFile({ dispatch }, uuid) {
      const response = await axios.delete(`/api/resource-files/${uuid}`)
      await dispatch('resource/getResourceFiles', '', { root: true })
      return response
    },
    async createResourceFile({ dispatch }, payload) {
      const formData = new FormData()
      if (payload.file) {
        formData.append('file', payload.file)
      }
      const { file, ...resourceFile } = payload
      formData.append('resource_file', JSON.stringify(resourceFile))
      const response = await axios.post('/api/resource-files', formData, { headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' } })
      await dispatch('resource/getResourceFiles', '', { root: true })
      await dispatch('resource/getTags', '', { root: true })
      return response
    },
    async getResourceFileDownloadURL({ dispatch }, uuid) {
      const response = await axios.patch(`/api/resource-files/${uuid}/download-link`)
      return response
    },
    async getTags({ commit }) {
      const response = await axios.get('/api/tags')
      await commit('resource/SET_TAGS', response.data, { root: true })
      return response
    },
  },
}
