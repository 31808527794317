/* eslint-disable no-unused-vars */
import axios from '@axios'
// import Cookies from 'js-cookie'

export default {
  namespaced: true,
  state: {
    reports: [],
    reportTypes: [],
    report: null,
  },
  getters: {
    reports: state => state.reports,
    reportTypes: state => state.reportTypes,
    report: state => state.report,
  },
  mutations: {
    SET_REPORTS(state, reports) {
      state.reports = reports
    },

    SET_REPORT_TYPES(state, reportTypes) {
      state.reportTypes = reportTypes
    },

    SET_REPORT(state, report) {
      state.report = report
    },
  },
  actions: {
    async getReports({ commit }) {
      const response = await axios.get('/api/reports')
      commit('report/SET_REPORTS', response.data, { root: true })
      return response
    },
    async getReportTypes({ commit }) {
      const response = await axios.get('/api/report_types')
      commit('report/SET_REPORT_TYPES', response.data, { root: true })
      return response
    },
    async createReport({ commit, dispatch }, payload) {
      const response = await axios.post('/api/reports', payload)
      commit('SET_REPORT', response.data, { root: true })
      await dispatch('report/getReports', '', { root: true })
      return response
    },
    async updateReport({ commit }, payload) {
      const { uuid, ...reduced } = payload
      const response = await axios.patch(`/api/reports/${uuid}`, reduced)
      commit('report/SET_REPORT', response.data, { root: true })
      return response
    },
    async getReport({ commit }, uuid) {
      const response = await axios.get(`/api/reports/${uuid}`)
      commit('report/SET_REPORT', response.data, { root: true })
      return response
    },
    async fetchPdfReport({ commit }, uuid) {
      const response = await axios({ url: `/api/reports/${uuid}?format=pdf`, method: 'GET', responseType: 'blob' })
      return response
    },
    async deleteReport({ dispatch }, uuid) {
      const response = await axios.delete(`/api/reports/${uuid}`)
      await dispatch('report/getReports', '', { root: true })
      return response
    },
  },
}
